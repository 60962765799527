var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "wrap-container", attrs: { direction: "vertical" } },
    [
      _c("el-main", { staticClass: "wrap-main" }, [
        _c("div", { staticClass: "form-designer-container" }, [
          _c(
            "div",
            { staticClass: "form-designer-left" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: { label: _vm.$t("lang_control"), name: "widget" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "widget-wrapper" },
                        _vm._l(
                          _vm.widgetTemplateList,
                          function (item, itemIndex) {
                            return _c(
                              "div",
                              {
                                key: itemIndex,
                                staticStyle: {
                                  "margin-bottom": "15px",
                                  "padding-right": "15px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                                _c(
                                  "draggable",
                                  {
                                    staticClass: "widget-drag-wrapper",
                                    attrs: {
                                      list: item.children,
                                      group: {
                                        name: "formDragableGroup",
                                        pull: "clone",
                                        put: false,
                                      },
                                      draggable: ".widget-item",
                                    },
                                  },
                                  _vm._l(
                                    item.children,
                                    function (element, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "widget-item",
                                          attrs: {
                                            "attr-widget-type":
                                              element.compType,
                                          },
                                        },
                                        [
                                          _c("com-icon", {
                                            staticClass: "widget-item-body",
                                            attrs: {
                                              code: element.compIcon,
                                              text: _vm.$t(element.compName),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("lang_outline_tree"),
                        name: "outline",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("lang_enter_keywords_to_filter"),
                        },
                        model: {
                          value: _vm.filterText,
                          callback: function ($$v) {
                            _vm.filterText = $$v
                          },
                          expression: "filterText",
                        },
                      }),
                      _c("el-tree", {
                        ref: "tree",
                        staticClass: "filter-tree",
                        attrs: {
                          data: _vm.widgetTree,
                          "current-node-key": _vm._activedWidget.id,
                          "default-expand-all": "",
                          "node-key": "id",
                          "highlight-current": "",
                          "expand-on-click-node": false,
                          "filter-node-method": _vm.handleOutlinesTreeFilter,
                        },
                        on: { "node-click": _vm.handleClickOutlinesTree },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-designer-main" },
            [
              _vm.formId
                ? _c("form-drawer", {
                    ref: "designer",
                    attrs: { formStatus: _vm.formStatus },
                    on: { change: _vm.changeFormConfig },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-designer-right" },
            [_c("ele-config")],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }