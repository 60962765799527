



























































import { Component, Vue, Watch, ProvideReactive } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import ComIcon from '@/components/form-design/elements/com-icon.vue';
import FormDrawer from '@/components/form-design/drawer/form-drawer.vue';
import EleConfig from '@/components/form-design/config/ele-config.vue';
import RiverHeader from '../layout/components/river-header/index.vue';
import { findAllFormConf, getFormConfig, getBusinessDataSource } from '@/api/form-design/FormApi';
import { FormData, FormModel } from '@/models/form/FormModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import StoreIndex from '@/store/StoreIndex';
import { getFormWidgetMap } from '@/components/form-design/utils';
@Component({
  name: 'FormDesigner',
  components: {
    ComIcon,
    draggable,
    FormDrawer,
    EleConfig,
    RiverHeader
  }
})
export default class Form extends Vue {
  //   @Prop() msg!: string;
  tabIndex = 'widget';
  formConfig: FormModel = FormData;
  showLeftAreaFlag: boolean = true;
  menuActive: string = '1';
  filterText: string = '';
  widgetTree: Record<string, any>[] = [];
  asideFixed: boolean = true;
  widgetTemplateList: any[] = [];
  formStatus: string = '0';

  @ProvideReactive()
  formId: any = null;

  @ProvideReactive()
  linkId: any = null;

  get _formWidgetMap() {
    return getFormWidgetMap(this.formId);
  }
  // 缓存中的当前选中表单项
  get _activedWidget() {
    return this.$store.getters.activeWidget;
  }

  created() {
    this.buildWidgetList();
    this.buildFormInfo();
  }

  // 构建左侧可拖拉控件
  buildWidgetList() {
    findAllFormConf({}).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        let result: any = [];
        res.data.forEach(item => {
          let rstItem = {
            title: item.groupName,
            children: item.componentsList
          };
          result.push(rstItem);
        });
        this.widgetTemplateList = result;
      } else {
        this.widgetTemplateList = [];
      }
    });
  }

  // 构建表单信息及组件信息, 初始化表单页面
  buildFormInfo() {
    this.linkId = this.$route.query.linkId;
    let formId = this.$route.query.formId || JSON.parse(sessionStorage.getItem('pFormId') as any);
    if (formId) {
      getFormConfig({ id: formId }).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          if (res.data.container.components) {
            this.buildOutlinesTree(res.data.container.components);
          } else {
            res.data.container.components = [];
            this.widgetTree = [];
          }
          this.formConfig = res.data;
          StoreIndex.commit('SET_FORM_CONFIG', res.data);
          this.formId = formId;
          this.formStatus = res.data.status;
        }
      });
    }
  }

  // 构建左侧大纲树
  buildOutlinesTree(widgetTemplateList) {
    this.widgetTree = [];
    let arr: Record<string, any>[] = [];
    widgetTemplateList.forEach((item, index) => {
      if (item.compType == 'row') {
        let obj: Record<string, any> = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType,
          children: []
        };
        item.properties.gridColumns.forEach((el, elIndex) => {
          el.components.forEach((child, childIndex) => {
            let childObj = {
              id: child.id,
              compCode: item.compCode,
              compType: child.compType,
              label: this.$t(child.compName)
            };
            obj.children.push(childObj);
          });
        });
        arr.push(obj);
      } else if (item.compType == 'table') {
        let obj: any = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType,
          children: []
        };
        item.properties.components.forEach((child, childIndex) => {
          let childObj = {
            id: child.id,
            compCode: item.compCode,
            compType: child.compType,
            label: this.$t(child.compName)
          };
          obj.children.push(childObj);
        });
        arr.push(obj);
      } else if (item.compType == 'card') {
        let obj: any = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType,
          children: []
        };
        item.properties.components.forEach((child, childIndex) => {
          let childObj = {
            id: child.id,
            compCode: item.compCode,
            compType: child.compType,
            label: this.$t(child.compName)
          };
          obj.children.push(childObj);
        });
        arr.push(obj);
      } else if (item.compType === 'html') {
        let obj = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType,
          node: item.node,
          dataSourceConfigs: item.dataSourceConfigs
        };
        arr.push(obj);
      } else {
        let obj = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType
        };
        arr.push(obj);
      }
    });
    let fatherObj = {
      id: '000001',
      code: null,
      compType: null,
      label: this.$t('lang_form'),
      children: arr
    };
    this.widgetTree.push(fatherObj);
  }

  // 树形过滤
  handleOutlinesTreeFilter(value, data) {
    if (!value) return true;
    return data.label.indexOf(value) !== -1;
  }

  // 点击大纲树
  handleClickOutlinesTree(val) {
    if (val.compCode) {
      let widget = this._formWidgetMap[val.compCode];
      if (widget) {
        StoreIndex.commit('SET_ACTIVED_WIDGET', widget);
      }
    }
  }

  // 点击大纲树时传过来的当前项
  changeFormConfig(formConfig) {
    this.formConfig = SystemUtil.cloneDeep(formConfig);
    if (this.formConfig.container.components) {
      this.buildOutlinesTree(this.formConfig.container.components);
    } else {
      this.formConfig.container.components = [];
      this.widgetTree = [];
    }
  }

  // 监听 树形过滤
  @Watch('filterText')
  onValueChange(value: string) {
    (this.$refs.tree as any).filter(value);
  }

  // 监听 树形过滤
  @Watch('_activedWidget')
  activedWidgetChange(widget: any) {
    (this.$refs.tree as any).setCurrentKey(widget.id);
  }
}
